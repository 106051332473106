import React from 'react'
import { useState } from 'react';
import WithdrawCard from '../../components/Withdraw';
import CreateTicket from './CreateTicket';
import { StyledTabs, StyledTab } from '../../components/ui/Tabs'
import { Box } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TicketHistory from './TicketHistory';
import UserGuide from './UserGuide';

export default function HelpAndSupport() {
		const [value, setValue] = useState('1');

		const handleChange = (event, newValue) => {
			setValue(newValue);
		};
	return (
		<div>
			<WithdrawCard />
			<br></br>
			<Box sx={{ width: '100%' }}>
				<TabContext value={value}>
					<Box>
						<StyledTabs
							value={value}
							onChange={handleChange}
							aria-label="styled tabs example"
						>
							<StyledTab label="Create New Ticket" value="1" />
							<StyledTab label="Ticket History" value="2" />
							<StyledTab label="User Guide" value="3" />
						</StyledTabs>
						<Box sx={{ p: 1 }} />
					</Box>
					<TabPanel value="1"><CreateTicket /></TabPanel>
					<TabPanel value="2" sx={{padding: 1}}><TicketHistory /></TabPanel>
					<TabPanel value="3" sx={{padding: 1}}><UserGuide /></TabPanel>
				</TabContext>
			</Box>
		</div>
	)
}

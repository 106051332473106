import React, { useState } from "react";
import {
	Stack,
	Typography,
	Card,
	CardContent,
	Button,
	Dialog,
	List,
	AppBar,
	IconButton,
	Toolbar,
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';

import LoginPage from "../../../assets/UserGuide/login.png";

export default function LoginGuide() {
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
	  setOpen(true);
	};
  
	const handleClose = () => {
	  setOpen(false);
	};

	return (
		<div>
			<Card sx={{ minWidth: 275, p:3, textAlign:'center', }} onClick={handleClickOpen}>
				<CardContent>
					<LoginRoundedIcon color="primary" sx={{ fontSize: 40 }}>add_circle</LoginRoundedIcon>
					<Typography variant="h3">
						User Login
					</Typography>
				</CardContent>
			</Card>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
			>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
						<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							User Login
						</Typography>
						<Button autoFocus color="inherit" onClick={handleClose}>
							Close
						</Button>
					</Toolbar>
				</AppBar>
				<List>
					<Stack direction="row" justifyContent="center" sx={{pt:3}}>
						<Stack spacing={1} sx={{p:3}}>						
							<Typography variant="h3">To access your VePay Client Portal, follow these steps:</Typography>
							<Typography variant="body1">1. Open the VePay Client Portal on your preferred web browser.</Typography>
							<Typography variant="body1">2. Enter your <b>Username</b> in the designated field.</Typography>
							<Typography variant="body1">3. Enter your <b>Password</b> in the password field.</Typography>
							<Typography variant="body1" sx={{pl:2}}>- Ensure your password is secure and avoid sharing it with anyone.</Typography>
							<Typography variant="body1">4. Click the <b>Login</b> button to access your account.</Typography>
						</Stack>
						<img src={LoginPage} alt="User Login Page" style={{width: '40%'}}/>
					</Stack>
				</List>
	  		</Dialog>
			<br></br>
		</div>
	);
}

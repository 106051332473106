import React, { useState } from "react";
import {
	Stack,
	Typography,
	Card,
	CardContent,
	Button,
	Dialog,
	List,
	AppBar,
	IconButton,
	Toolbar,
	TableRow,
	TableBody,
	TableHead,
	Table
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';

import DailySummary1 from "../../../assets/UserGuide/dailysummary1.png";
import DailySummary2 from "../../../assets/UserGuide/dailysummary2.png";
import DailySummary3 from "../../../assets/UserGuide/dailysummary3.png";
import DailySummary4 from "../../../assets/UserGuide/dailysummary4.png";
import { BlueBox, RedBox, GreenBox } from "../../../components/ui/Chip";
import { StyledTableCellBody, StyledTableCellHeader, StyledTableContainer } from "../../../components/ui/Table";

export default function DailySummaryGuide() {
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
	  setOpen(true);
	};
  
	const handleClose = () => {
	  setOpen(false);
	};

	return (
		<div>
			<Card sx={{ minWidth: 275, p:3, textAlign:'center', }} onClick={handleClickOpen}>
				<CardContent>
					<QueryStatsRoundedIcon color="primary" sx={{ fontSize: 40 }}>add_circle</QueryStatsRoundedIcon>
					<Typography variant="h3">
						Daily Summary
					</Typography>
				</CardContent>
			</Card>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
			>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
						<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							Daily Summary Guide
						</Typography>
						<Button autoFocus color="inherit" onClick={handleClose}>
							Close
						</Button>
					</Toolbar>
				</AppBar>
				<List>
					<Stack direction="row" justifyContent="center" sx={{pt:3}}>
						<Stack spacing={1} sx={{p:3}}>	
							<img src={DailySummary1} alt="Daily Summary Main" style={{width: '50%'}}/>					
							<Typography variant="body1">The <b>Daily Summary</b> provides a detailed breakdown of your current balances and transaction activities.:</Typography>
							<br></br>
							<Typography variant="h4">1. Primary Balance Widget</Typography>
							<Typography variant="body1" sx={{pl:2}}>- Displays three key balances updated as of today:</Typography>
								<Typography variant="body1" sx={{pl:4}}>- <b>Available Balance</b>: Funds available for immediate withdrawal.</Typography>
								<Typography variant="body1" sx={{pl:4}}>- <b>Receivable Balance</b>: Outstanding funds that will be paid later.</Typography>
								<Typography variant="body1" sx={{pl:4}}>- <b>Marketplace Balance</b>: Net income after deducting refunds and marketplace fees.</Typography>
							<Typography variant="h4">2. Withdrawal Status Widget</Typography>
								<Typography variant="body1" sx={{pl:2}}>This section monitors the status of your withdrawal requests</Typography>
								<Typography variant="body1" sx={{pl:2}}>2.1 Pending Withdrawal Amount.</Typography>
									<Typography variant="body1" sx={{pl:4}}>- Amounts awaiting VePay’s approval.</Typography>
								<Typography variant="body1" sx={{pl:2}}>2.2 Current Available Amount.</Typography>
									<Typography variant="body1" sx={{pl:4}}>- Calculated as: Available Balance - <b>Pending Withdrawal Amount</b>.</Typography>
									<Typography variant="body1" sx={{pl:4}}>- Updates once pending requests are approved.</Typography>
								<Typography variant="body1" sx={{pl:2}}>2.3 Pending Card Transactions.</Typography>
									<Typography variant="body1" sx={{pl:4}}>- If funded amounts remain unpaid for over 30 days, they are recoverable via your registered credit card.</Typography>
							<img src={DailySummary2} alt="Withdrawal Status Widget" style={{width: '30%'}}/>
							<Typography variant="h4">3. Daily Transaction Summary</Typography>
								<Typography variant="body1" sx={{pl:2}}>A table summarizing all transactions for the day, categorized as follows:</Typography>
								<Typography variant="body1" sx={{pl:2}}>3.1 The Non Collapse View.</Typography>
								<Typography variant="body1" sx={{pl:2}}>1. <b>Table Heading</b>: This provides a labeling that represent the data in its respective columns. Below is the explanation:</Typography>
									<Typography variant="body1" sx={{pl:4}}>a. <b>Data</b>: Each transaction available dates. If there is no transaction available for a specific date the that specific date will not be displayed.</Typography>
									<Typography variant="body1" sx={{pl:4}}>b. <b>Total Amount</b>: The total Amount related to a specific transaction type. This amount will only be displayed in the collapse view.</Typography>
									<Typography variant="body1" sx={{pl:4}}>c. <b>Available Balance</b>: This is the accumulative balance that the Seller will be able to withdraw.</Typography>
									<Typography variant="body1" sx={{pl:4}}>d. <b>(Percentage) %</b>: This indicates the percentage of funding received. This will be visible only in the collapse view.</Typography>
									<Typography variant="body1" sx={{pl:4}}>e. <b>Receivable Balance</b>: This is the accumulated balance of the total amount that the Seller would receive after Marketplace is released.</Typography>
									<Typography variant="body1" sx={{pl:4}}>f. <b>Marketplace Balance</b>: This is the accumulated balance from the Marketplace net proceeds.</Typography>

								<Typography variant="body1" sx={{pl:2}}>2. Date Non Collapse View: Clicking on this collapse view would expand the aggregate total of transaactions for the day. This includes:</Typography>
									<Typography variant="body1" sx={{pl:4}}>- <b>Invoice Funded</b>: This is the total Amount VePay has funded for the day. This amount is equals 70% of the Net Sales Amount for the day in the Marketplace.</Typography>
									<Typography variant="body1" sx={{pl:4}}>- <b>Product Charges</b>: This includes Invoice-specific costs such as, FBA Fee, Referral Fee, and Delivery Charges Included any tax applicable.</Typography>
									<Typography variant="body1" sx={{pl:4}}>- <b>VePay Fee</b>: Service charges from VePay. This is equlant to 2% of the Total Net Sale amount.</Typography>
									<Typography variant="body1" sx={{pl:4}}>- <b>Marketplace Charges</b>: These include any non-product-specific costs such as cost of advertising, subscription fee, or Inventory reimbursement.</Typography>
									<Typography variant="body1" sx={{pl:4}}>- <b>Marketplace Payment</b>: This is the proceeds received from marketplaces. Typically this will be release on a regular cycle which can vary according to the marketplace.</Typography>
									<Typography variant="body1" sx={{pl:4}}>- <b>Withdrawals</b>: Funds withdrawn by Seller from the available balance.</Typography>

								<Typography variant="body1" sx={{pl:2}}>3. <b>Accumulated Balance</b>: This is the total accumulated balance from each balance. The difference between two days represents the net <b>increase/decrease</b> from the total transaction. This would behave in the below way:</Typography>
								{/* Table here */}
								<StyledTableContainer >
									<Table sx={{ minWidth: 450 }} aria-label="simple table">
										<TableHead>
											<TableRow sx={{ backgroundColor: "#F6F6F6" }}>
												<StyledTableCellHeader align="left" sx={{width: '25%'}}>Balance Type</StyledTableCellHeader>
												<StyledTableCellHeader align="left" sx={{width: '25%'}}>Available Balance</StyledTableCellHeader>
												<StyledTableCellHeader align="left" sx={{width: '25%'}}>Receivable Balance</StyledTableCellHeader>
												<StyledTableCellHeader align="left" sx={{width: '25%'}}>Marketplace Balance</StyledTableCellHeader>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<StyledTableCellBody align="left">
													Invoice Funded
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													<Stack alignItems="left">
														<GreenBox>
															<b>Increase</b>
														</GreenBox>
													</Stack>
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													<Stack alignItems="left">
														<GreenBox>
															<b>Increase</b>
														</GreenBox>
													</Stack>
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													<Stack alignItems="left">
														<GreenBox>
															<b>Increase</b>
														</GreenBox>
													</Stack>
												</StyledTableCellBody>
											</TableRow>
											<TableRow>
												<StyledTableCellBody align="left">
													Refund
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													<Stack alignItems="left">
														<RedBox>
															<b>Decrease</b>
														</RedBox>
													</Stack>
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													<Stack alignItems="left">
														<RedBox>
															<b>Decrease</b>
														</RedBox>
													</Stack>
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													<Stack alignItems="left">
														<RedBox>
															<b>Decrease</b>
														</RedBox>
													</Stack>
												</StyledTableCellBody>
											</TableRow>
											<TableRow>
												<StyledTableCellBody align="left">
													Marketplace Charges
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													<Stack alignItems="left">
														<RedBox>
															<b>Decrease</b>
														</RedBox>
													</Stack>
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													<Stack alignItems="left">
														<RedBox>
															<b>Decrease</b>
														</RedBox>
													</Stack>
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													<Stack alignItems="left">
														<RedBox>
															<b>Decrease</b>
														</RedBox>
													</Stack>
												</StyledTableCellBody>
											</TableRow>
											<TableRow>
												<StyledTableCellBody align="left">
													Marketplace Payment
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													<Stack alignItems="left">
														<GreenBox>
															<b>Increase</b>
														</GreenBox>
													</Stack>
												</StyledTableCellBody>
											</TableRow>
											<TableRow>
												<StyledTableCellBody align="left">
													VePay Fee
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													<Stack alignItems="left">
														<BlueBox>
															<b>Doesn't Change</b>
														</BlueBox>
													</Stack>
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													<Stack alignItems="left">
														<RedBox>
															<b>Decrease</b>
														</RedBox>
													</Stack>
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													<Stack alignItems="left">
														<BlueBox>
															<b>Doesn't Change</b>
														</BlueBox>
													</Stack>
												</StyledTableCellBody>
											</TableRow>
											<TableRow>
												<StyledTableCellBody align="left">
													Withdrawal
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													<Stack alignItems="left">
														<RedBox>
															<b>Decrease</b>
														</RedBox>
													</Stack>
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													<Stack alignItems="left">
													<BlueBox>
															<b>Doesn't Change</b>
														</BlueBox>
													</Stack>
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													<Stack alignItems="left">
														<BlueBox>
															<b>Doesn't Change</b>
														</BlueBox>
													</Stack>
												</StyledTableCellBody>
											</TableRow>
										</TableBody>
									</Table>
								</StyledTableContainer>
								<Typography variant="body1" sx={{pl:2}}>4. <b>Quick Collapse/Non Collapse Icon</b>: This will enable to expand the all the daily fields to be viewed and clicking again would non-collapse the fields.</Typography>
								<Typography variant="body1" sx={{pl:2}}>5. <b>Print</b>: This icon enables to print the current view into an excel file.</Typography>
								<img src={DailySummary3} alt="Table 1" style={{width: '50%', paddingLeft: '2%'}}/>
								<Typography variant="body1" sx={{pl:2}}>6. <b>Daily Summary</b> Collapse View.</Typography>
								{/* Table here */}
								<StyledTableContainer >
									<Table sx={{ minWidth: 450 }} aria-label="simple table">
										<TableHead>
											<TableRow sx={{ backgroundColor: "#F6F6F6" }}>
												<StyledTableCellHeader align="left" sx={{width: '20%'}}>Balance Type</StyledTableCellHeader>
												<StyledTableCellHeader align="left" sx={{width: '20%'}}>Total Amount Due</StyledTableCellHeader>
												<StyledTableCellHeader align="left" sx={{width: '20%'}}>Available Balance</StyledTableCellHeader>
												<StyledTableCellHeader align="left" sx={{width: '20%'}}>Receivable Balance</StyledTableCellHeader>
												<StyledTableCellHeader align="left" sx={{width: '20%'}}>Marketplace Balance</StyledTableCellHeader>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<StyledTableCellBody align="left">
													Invoice Funded
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													The total Gross Sales Amount for a specific Day
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													The Total Funded Amount from the invoices per day. This is calculated as 70% of Net Sales Amount per Day (Amount under Total Marketplace)
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													This is calculated as 30% based on the Net Sale Amount for the day. This includes: The total Balance Receivable Amount (which consist 28% of Total Net Sales Amount) 
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													This is the total Amount of Net Sales from the Marketplace
												</StyledTableCellBody>
											</TableRow>
											<TableRow>
												<StyledTableCellBody align="left">
													Refund
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													Total Net Refund Amount for a specific day
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													This amount is calculated as 70% from the Net Refund Amount. This will be a reduction from the Available Balance. 
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													This amount is calculated as 30% from the Net Refund Amount. This will be deducted from the Total Receivable Amount. 
												</StyledTableCellBody>
												<StyledTableCellBody align="left"></StyledTableCellBody>
											</TableRow>
											<TableRow>
												<StyledTableCellBody align="left">
													Marketplace Charges
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													Total Marketplace charges for a specific Day
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													This amount is calculated as 70% from the Marketplace Charges Amount. This will be a reduction from the Available Balance. 
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													This amount is calculated as 30% from the Marketplace Charges Amount . This will be deducted from the Total Receivable Amount. 
												</StyledTableCellBody>
												<StyledTableCellBody align="left"></StyledTableCellBody>
											</TableRow>
											<TableRow>
												<StyledTableCellBody align="left">
													VePay Fee
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													Total VePay Fee
												</StyledTableCellBody>
												<StyledTableCellBody align="left"></StyledTableCellBody>
												<StyledTableCellBody align="left">
													This amount is calculated as 2% from the Net Sale amount. This amount is deducted from the Total Receivable Amount. 
												</StyledTableCellBody>
												<StyledTableCellBody align="left"></StyledTableCellBody>
											</TableRow>
											<TableRow>
												<StyledTableCellBody align="left">
													Marketplace Payment
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													Total Marketplace Payment received
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													If there is an amount transferred to the seller wallet. This will include any Agency Fee or Incentive Fee Payable released. This will increase the Available Balance
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													If any Agency Fee or Incentive Fee Payable is released, this will be deducted from the Receivable Balance
												</StyledTableCellBody>
												<StyledTableCellBody align="left">
													The total Marketplace Payment will be deducted from the Marketplace. 
												</StyledTableCellBody>
											</TableRow>
										</TableBody>
									</Table>
								</StyledTableContainer>
								<br></br>
								<img src={DailySummary4} alt="Table 2" style={{width: '50%', paddingLeft: '2%'}}/>
								<br></br>
							<br></br>	
						</Stack>
					</Stack>
				</List>
	  		</Dialog>
			<br></br>
		</div>
	);
}

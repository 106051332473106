import React, { useState } from "react";
import {
	Stack,
	Typography,
	Card,
	CardContent,
	Button,
	Dialog,
	List,
	AppBar,
	IconButton,
	Toolbar,
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import PriceChangeRoundedIcon from '@mui/icons-material/PriceChangeRounded';

import Withdrawal1 from "../../../assets/UserGuide/withdrawal1.png";
import Withdrawal2 from "../../../assets/UserGuide/withdrawal2.png";
import Withdrawal3 from "../../../assets/UserGuide/withdrawal3.png";
import Withdrawal4 from "../../../assets/UserGuide/withdrawal4.png";
import Withdrawal5 from "../../../assets/UserGuide/withdrawal5.png";


export default function WithdrawalsGuide() {
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
	  setOpen(true);
	};
  
	const handleClose = () => {
	  setOpen(false);
	};

	return (
		<div>
			<Card sx={{ minWidth: 275, p:3, textAlign:'center', }} onClick={handleClickOpen}>
				<CardContent>
					<PriceChangeRoundedIcon color="primary" sx={{ fontSize: 40 }}>add_circle</PriceChangeRoundedIcon>
					<Typography variant="h3">
                        Withdrawals
					</Typography>
				</CardContent>
			</Card>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
			>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
						<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Withdrawals Guide
						</Typography>
						<Button autoFocus color="inherit" onClick={handleClose}>
							Close
						</Button>
					</Toolbar>
				</AppBar>
				<List>
					<Stack direction="row" justifyContent="center" sx={{pt:3}}>
						<Stack spacing={1} sx={{p:3}}>	
                            <Typography variant="h3">This page helps you manage withdrawals and view your withdrawal history.</Typography>
                            <img src={Withdrawal1} alt="Withdrawal Main" style={{width: '40%'}}/>	
                            <br></br>				
							<Typography variant="h4">1. Primary Balance Widget</Typography>
							<Typography variant="body1" sx={{pl:2}}>- Displays three key balances updated as of today:</Typography>
								<Typography variant="body1" sx={{pl:4}}>- <b>Available Balance</b>: Funds available for immediate withdrawal.</Typography>
								<Typography variant="body1" sx={{pl:4}}>- <b>Receivable Balance</b>: Outstanding funds that will be paid later.</Typography>
								<Typography variant="body1" sx={{pl:4}}>- <b>Marketplace Balance</b>: Net income after deducting refunds and marketplace fees.</Typography>
                                <img src={Withdrawal2} alt="Primary Balance Widget" style={{width: '30%'}}/>
                            <Typography variant="h4">2. Withdrawal Status Widget</Typography>
								<Typography variant="body1" sx={{pl:2}}>This section monitors the status of your withdrawal requests</Typography>
								<Typography variant="body1" sx={{pl:2}}>2.1 Pending Withdrawal Amount.</Typography>
									<Typography variant="body1" sx={{pl:4}}>- Amounts awaiting VePay’s approval.</Typography>
								<Typography variant="body1" sx={{pl:2}}>2.2 Current Available Amount.</Typography>
									<Typography variant="body1" sx={{pl:4}}>- Calculated as: Available Balance - <b>Pending Withdrawal Amount</b>.</Typography>
									<Typography variant="body1" sx={{pl:4}}>- Updates once pending requests are approved.</Typography>
								<Typography variant="body1" sx={{pl:2}}>2.3 Pending Card Transactions.</Typography>
									<Typography variant="body1" sx={{pl:4}}>- If funded amounts remain unpaid for over 30 days, they are recoverable via your registered credit card.</Typography>
							    <img src={Withdrawal3} alt="Withdrawal Status Widget" style={{width: '30%'}}/>
                            <Typography variant="h4">3. Withdrawal History</Typography>
                                <Typography variant="body1" sx={{pl:2}}>- A graph showing your historical withdrawal trends. The graph shows you the accumulated withdrawals for the entire month. This includes total approved and pending withdrawals.</Typography>
                                <img src={Withdrawal4} alt="Withdrawal History" style={{width: '30%'}}/>
                            <Typography variant="h4">4. Withdrawal Details</Typography>
                                <Typography variant="body1" sx={{pl:2}}>A table providing detailed records of withdrawal requests.</Typography>
                                <Typography variant="body1" sx={{pl:2}}><b>Transfer Request Date</b>: This is the date the transfer is initiated by the Seller.</Typography>
                                <Typography variant="body1" sx={{pl:2}}><b>Transfer Amount</b>: this is the Amount the Seller has requested to transfer.</Typography>
                                <Typography variant="body1" sx={{pl:2}}><b>Transfer Fee</b>: If there is any transfer fee, this will be included here.</Typography>
                                <Typography variant="body1" sx={{pl:2}}><b>Final Transfer Amount</b>: This is the total of Request Amount minus Any transfer Fee.</Typography>
                                <Typography variant="body1" sx={{pl:2}}><b>Bank Account</b>: The Seller's bank  account details.</Typography>
                                <Typography variant="body1" sx={{pl:2}}><b>Request Staus</b>: This consists of three status:</Typography>
                                <Typography variant="body1" sx={{pl:4}}><b>Pending</b>: When a transfer has been initiated and waiting for approval by VePay</Typography>
                                <Typography variant="body1" sx={{pl:4}}><b>Approved</b>: When the Transfer has initiated and approved by VePay and finally transferred to the Seller's account.</Typography>
                                <Typography variant="body1" sx={{pl:4}}><b>Declined</b>: If for any regulatory or other flags the transfer is denied, this will be indicated as a decline and the reason will be given for the decline.</Typography>
                                <Typography variant="body1" sx={{pl:2}}><b>Transfer Completed Date</b>: This is the date the Seller receives the withdrawal in his account</Typography>
                                <Typography variant="body1" sx={{pl:2}}><b>View Note</b>: If there is a decline or required Seller's attention, a note will be sent to this section. When there is a note, the icon will be blue and clickable.

                                </Typography>
                                <img src={Withdrawal5} alt="Withdrawal History" style={{width: '30%'}}/>

						</Stack>
					</Stack>
				</List>
	  		</Dialog>
			<br></br>
		</div>
	);
}

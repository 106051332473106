import React from "react";
import {
	Box,
	Grid,
	Stack,
	Typography,
} from "@mui/material";

import LoginGuide from "./Login";
import DashboardGuide from "./Dashboard";
import DailySummaryGuide from "./DailySummary";
import WithdrawalsGuide from "./Withdrawals";
import ReportsGuide from "./Reports";

export default function UserGuide() {

	return (
		<div>
			<Box
				sx={{
					backgroundColor: "#FFFFFF",
					boxShadow: "0px 0px 4px rgba(3, 40, 120, 0.18)",
					borderRadius: "2px",
				}}
			>
				<Stack sx={{p:3}}>
					<Typography variant="h3">VePay Client Portal User Manual</Typography>
					<Typography variant="body1">Welcome to the VePay Client Portal! This manual is designed to help you understand and use all the features available in the portal with ease.
						 Whether you’re tracking balances, withdrawing funds, or generating reports, this guide will walk you through the steps and provide clear explanations.</Typography>
				</Stack>

				<Grid container spacing={2} sx={{p:1}}>
					<Grid item xs={12} sm={4} md={3}>
						<LoginGuide />
					</Grid>
					<Grid item xs={12} sm={4} md={3}>
						<DashboardGuide />
					</Grid>
					<Grid item xs={12} sm={4} md={3}>
						<DailySummaryGuide />
					</Grid>
					<Grid item xs={12} sm={4} md={3}>
						<WithdrawalsGuide />
					</Grid>
					<Grid item xs={12} sm={4} md={3}>
						<ReportsGuide />
					</Grid>
				</Grid>
			</Box>
			<br></br>
		</div>
	);
}

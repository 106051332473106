import * as React from "react";
import { useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  // Toolbar,
  Typography,
  Button,
  StepLabel,
  Step,
  Stepper,
  Box,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PersonalInfo from "./PersonalInfor";
import BusinessInfo from "./BusinessInfo";
import BankInfo from "./BankInfo";
import LinkedMarketplaces from "./LinkedMarketplaces";
// import RiskAnalysis from "./RiskAnalysis";
import UploadandSign from "./UploadandSign";
import Logo from "../../assets/logo.svg";
import Completed from "./Completed";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AodIcon from "@mui/icons-material/Aod";
import BusinessIcon from "@mui/icons-material/Business";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
// import AnalyticsIcon from "@mui/icons-material/Analytics";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckIcon from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Check from "@mui/icons-material/Check";
// import { GreenContainedButton } from "../../components/ui/Buttons";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useEffect } from "react";

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#03AD47",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#03AD47",
    zIndex: 1,
    fontSize: 18,
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: "#03AD47",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: "#03AD47",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: "#C3C7D3",
  zIndex: 1,
  width: 50,
  height: 50,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    color: "#03AD47",
  }),
  ...(ownerState.completed && {
    color: "#03AD47",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <AccountCircleIcon fontSize="large" />,
    2: <AodIcon fontSize="large" />,
    3: <BusinessIcon fontSize="large" />,
    4: <AccountBalanceIcon fontSize="large" />,
    // 5: <AnalyticsIcon fontSize="large" />,
    5: <CloudUploadIcon fontSize="large" />,
    6: <CheckIcon fontSize="large" />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = [
  "Personal Information",
  "Linked Marketplaces",
  "Business Information",
  "Bank Information",
  // "Risk Analysis",
  "Upload and Sign",
  "Successfully Completed",
];

export default function Onboarding() {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const updateStep = useCallback((value) => {
    setActiveStep(value);
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const markeplacelinked = urlParams.get("markeplacelinked");

    if(markeplacelinked === 'true') {
      console.log(markeplacelinked)
      setActiveStep(2);
    }

    if(markeplacelinked === 'false') {
      setActiveStep(1);
    }
  }, [])

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ flexGrow: 1, background: "#F9F9FB" }}>
      <AppBar position="static">
        <Stack
          direction="row"
          alignContent="center"
          justifyContent="center"
          sx={{ m: 2 }}
        >
            <img src={Logo} alt="VePay" style={{width: '10%'}}/>
        </Stack>
      </AppBar>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          component="main"
          sx={{ flexGrow: 1, pt: 5, pl: 10, pr: 10, maxWidth: 1024 }}
        >
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <br></br>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep === 0 ? (
                <PersonalInfo updateStep={updateStep} />
              ) : activeStep === 1 ? (
                <LinkedMarketplaces updateStep={updateStep} />
              ) : activeStep === 2 ? (
                <BusinessInfo updateStep={updateStep} />
              ) : activeStep === 3 ? (
                <BankInfo updateStep={updateStep} />
              ) 
              //: activeStep === 4 ? (
              //   <RiskAnalysis updateStep={updateStep} />
              // ) 
              : activeStep === 4 ? (
                <UploadandSign updateStep={updateStep} />
              ) 
              : activeStep === 5 ? (
                <Completed />
              ) : (
                ""
              )}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2, pb: 5 }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1, color:"#f9f9fb !important" }}
                  startIcon={<ArrowBackIosNewIcon />}
                >
                  Previous
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {isStepOptional(activeStep) && (
                  <Button onClick={handleSkip} sx={{ mr: 1, color:"#f9f9fb !important"}}>
                    Skip
                  </Button>
                )}
                <Button
                  onClick={handleNext}
                  endIcon={<ArrowForwardIosIcon />}
                  sx={{color:"#f9f9fb"}}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Box>
    </Box>
  );
}

import React, { useState } from "react";
import {
	Stack,
	Typography,
	Card,
	CardContent,
	Button,
	Dialog,
	List,
	AppBar,
	IconButton,
	Toolbar,
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';

import Dashboard1 from "../../../assets/UserGuide/dashboard1.png";
import Dashboard2 from "../../../assets/UserGuide/dashboard2.png";
import Dashboard3 from "../../../assets/UserGuide/dashboard3.png";
import Dashboard4 from "../../../assets/UserGuide/dashboard4.png";
// import Dashboard5 from "../../../assets/UserGuide/dashboard5.png";
import Dashboard6 from "../../../assets/UserGuide/dashboard6.png";
import Dashboard7 from "../../../assets/UserGuide/dashboard7.png";

export default function DashboardGuide() {
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
	  setOpen(true);
	};
  
	const handleClose = () => {
	  setOpen(false);
	};

	return (
		<div>
			<Card sx={{ minWidth: 275, p:3, textAlign:'center', }} onClick={handleClickOpen}>
				<CardContent>
					<DashboardRoundedIcon color="primary" sx={{ fontSize: 40 }}>add_circle</DashboardRoundedIcon>
					<Typography variant="h3">
						Dashboard
					</Typography>
				</CardContent>
			</Card>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
			>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
						<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							Dashboard Guide
						</Typography>
						<Button autoFocus color="inherit" onClick={handleClose}>
							Close
						</Button>
					</Toolbar>
				</AppBar>
				<List>
					<Stack direction="row" justifyContent="center" sx={{pt:3}}>
						<Stack spacing={1} sx={{p:3}}>
							<img src={Dashboard1} alt="Dashboard Main" style={{width: '50%'}}/>					
							<Typography variant="body1">The <b>Dashboard</b> gives you a snapshot of your account, including balances, withdrawal options, and marketplace metrics.</Typography>
							<br></br>
							<Typography variant="h4">1. Welcome Widget</Typography>
							<Typography variant="body1" sx={{pl:2}}>- This widget greets you personally by name and confirms the service type you’re using (e.g., InstaPay) along with your account status (e.g., Active).</Typography>
							<Typography variant="body1" sx={{pl:2}}>- Purpose: Provides a quick confirmation that your account is active and functioning.</Typography>
							<img src={Dashboard2} alt="Welcome Widget" style={{width: '30%'}}/>
							<br></br>			
							<Typography variant="h4">2. Available Balance</Typography>
							<Typography variant="body1" sx={{pl:2}}>- This section shows how much money you can withdraw immediately.</Typography>
							<img src={Dashboard3} alt="Available Balance" style={{width: '30%'}}/>
								<Typography variant="h4" sx={{pl:2}}>2.1 Withdrawal Process</Typography>
								<Typography variant="h4" sx={{pl:4}}>Follow these steps to withdraw funds</Typography>
								<Typography variant="body1" sx={{pl:4}}>1. Click the <b>Withdraw</b> button to open the withdrawal form.</Typography>
								<Typography variant="body1" sx={{pl:4}}>2. Enter Withdrawal Amount:</Typography>
									<Typography variant="body1" sx={{pl:6}}>- Type the desired amount in the provided text box.</Typography>
									<Typography variant="body1" sx={{pl:6}}>- Alternatively, click <b>Transfer All</b> to automatically fill in the maximum available balance.</Typography>
								<Typography variant="body1" sx={{pl:4}}>3. Transfer Method:</Typography>
									<Typography variant="body1" sx={{pl:6}}>- By default, the withdrawal will be sent to your registered bank account.</Typography>
								<Typography variant="body1" sx={{pl:4}}>4. Transfer Details:</Typography>
									<Typography variant="body1" sx={{pl:6}}>- The system will display the amount you’re withdrawing and any applicable transfer fees.</Typography>
								<Typography variant="body1" sx={{pl:4}}>5. Confirm Withdrawal:</Typography>
									<Typography variant="body1" sx={{pl:6}}>- Click <b>Withdraw Now</b> to process the request.</Typography>
									<Typography variant="body1" sx={{pl:6}}>- If you don’t wish to proceed, click <b>Cancel</b> to exit.</Typography>
								<Typography variant="body1" sx={{pl:4}}>6. Processing Time:</Typography>
									<Typography variant="body1" sx={{pl:6}}>- Funds are typically credited to your bank account <b>Instantly</b>.</Typography>
									<img src={Dashboard4} alt="Processing Time" style={{width: '30%'}}/>
							<Typography variant="h4">3. Marketplace Summary Widget</Typography>
							<Typography variant="body1" sx={{pl:2}}>This widget summarizes key marketplace metrics to give you a clear financial picture.</Typography>
								<Typography variant="h4" sx={{pl:2}}>3.1 Metrics Explained</Typography>
									<Typography variant="body1" sx={{pl:4}}>- <b>Invoice Funded Amount</b>: The total 70% funding received from VePay for your invoices. </Typography>
									<Typography variant="body1" sx={{pl:4}}>- <b>Receivable Balance</b>: The remaining amount after marketplace payments, deducting the funded amount, VePay fees, and adjustments.</Typography>
									<Typography variant="body1" sx={{pl:4}}>- <b>Marketplace Balance</b>: Net proceeds from your marketplace after accounting for sales, refunds, and charges.</Typography>
									<img src={Dashboard6} alt="Processing Time" style={{width: '30%'}}/>
								<Typography variant="h4" sx={{pl:2}}>3.2 Visual Insights</Typography>
									<Typography variant="body1" sx={{pl:4}}>- A <b>pie chart</b> visually represents the ratio of Net Sales to Net Returns, helping you track overall performance at a glance </Typography>
								<Typography variant="h4" sx={{pl:2}}>3.3 Filters</Typography>
									<Typography variant="body1" sx={{pl:4}}>- Use the <b>date range filter</b> to adjust the data displayed in the summary.</Typography>
									<Typography variant="body1" sx={{pl:4}}>- or you can use pre-defined filters for:</Typography>
										<Typography variant="body1" sx={{pl:6}}>- 1M: For the last month</Typography>
										<Typography variant="body1" sx={{pl:6}}>- 6M: For the last Six Month</Typography>
										<Typography variant="body1" sx={{pl:6}}>- YTD: From the beginning of the year</Typography>
										<Typography variant="body1" sx={{pl:6}}>- 1Y: for the last one year</Typography>
										<img src={Dashboard7} alt="Processing Time" style={{width: '30%'}}/>
						</Stack>
					</Stack>
				</List>
	  		</Dialog>
			<br></br>
		</div>
	);
}

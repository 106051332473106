import {
  Typography,
  Grid,
  Stack,
  MenuItem,
  Box,
  InputAdornment,
  IconButton,
  Dialog,
  Snackbar,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { StyledTextBoxv2 } from "../../components/ui/TextBox";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import {
  BlackOutlinedButton,
  GreenContainedButton,
} from "../../components/ui/Buttons";
import "react-phone-input-2/lib/style.css";
import "./style.css";
import PhoneInput from "react-phone-input-2";
import validator from "validator";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { SellerSignUp } from "../../services/auth.services";
import { geocodeByAddress } from "react-google-places-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const boxStyle = {
  background: "#FFFFFF",
  p: 3,
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
  borderRadius: "10px",
};

export default function PersonalInfo({ updateStep }) {
  const [userInfo, setUserInfo] = useState({
    country: "",
    currency: "USD",
    email: "",
    firstName: "",
    lastName: "",
    state: "",
    city: "",
    timezone: "UTC-4 New York, United States",
    zipCode: "",
  });

  const [address, setAddress] = useState("");
  const [contactNo, setContactNo] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setPasswordConfirm] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [errorCheckFirstName, setErrorCheckFirstName] = useState(false);
  const [errorCheckLastName, setErrorCheckLastName] = useState(false);
  const [errorCheckContactNo, setErrorCheckContactNo] = useState(false);
  const [errorCheckEmail, setErrorCheckEmail] = useState(false);
  const [errorCheckPassword, setErrorCheckPassword] = useState(false);
  const [errorCheckPasswordConfirm, setErrorCheckPasswordConfirm] =
    useState(false);

  const [emailMessage, setEmailMessage] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");
  const [passwordConfirmMessage, setPasswordConfirmMessage] = useState("");
  const [snackBarAlert, setSnackBarAlert] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const [open, setOpen] = useState(false);

  const addressAPIKey = 'AIzaSyD9xcEWBs-_x0-nLofudKB0sT8UeI51Wi0';
  //AIzaSyADsyiSRV3CsnVV71hbkuwZhXhVU4kgeMs

  useEffect(() => {
    if (address) {
      geocodeByAddress(address.label).then((place) => {
        let updatedUserInfo = { ...userInfo }; // Create a copy of userInfo
        setAddress(address.label)
        for (var i = 0; i < place[0].address_components.length; i++) {
          var addressType = place[0].address_components[i].types[0];

          switch (addressType) {
            case 'locality':
              updatedUserInfo.city = place[0].address_components[i].long_name;
              break;
            case 'administrative_area_level_1':
              updatedUserInfo.state = place[0].address_components[i].long_name;
              break;
            case 'country':
              updatedUserInfo.country = place[0].address_components[i].long_name;
              break;
            case 'postal_code':
              updatedUserInfo.zipCode = place[0].address_components[i].long_name;
              break;
            default:
              break;
          }
        }

        setUserInfo(updatedUserInfo); // Update state once after loop
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    let error = false;

    var letters = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
    );

    if (userInfo.email === "" || userInfo.email === null) {
      error = true;
      setErrorCheckEmail(true);
    } else {
      setErrorCheckEmail(false);
    }

    if (validator.isEmail(userInfo.email)) {
      setEmailMessage("");
      setErrorCheckEmail(false);
    } else {
      setEmailMessage("Enter a valid email");
      setErrorCheckEmail(true);
      error = true;
    }

    if (password === "" || password === null) {
      error = true;
      setErrorCheckPassword(true);
    } else {
      setErrorCheckPassword(false);
    }

    if (password.length < 8) {
      error = true;
      setErrorCheckPassword(true);
      setPasswordMessage("Passwords length need to be more than 8 Characters");
    } else {
      setErrorCheckPassword(false);
      setPasswordMessage("");
    }

    if (!letters.test(password)) {
      error = true;
      setErrorCheckPassword(true);
      setPasswordMessage(
        "To become strong password, it must contain number, special character and capital letters"
      );
    } else {
      setErrorCheckPassword(false);
      setPasswordMessage("");
    }

    if (confirmPassword !== password) {
      error = true;
      setPasswordConfirmMessage("Passwords don't match");
      setErrorCheckPasswordConfirm(true);
    } else {
      setPasswordConfirmMessage("");
      setErrorCheckPasswordConfirm(false);
    }

    if (userInfo.firstName === null || userInfo.firstName === "") {
      setErrorCheckFirstName(true);
      error = true;
    } else {
      setErrorCheckFirstName(false);
    }

    if (userInfo.lastName === null || userInfo.lastName === "") {
      setErrorCheckLastName(true);
      error = true;
    } else {
      setErrorCheckLastName(false);
    }

    if (contactNo === null || contactNo === "" || contactNo === undefined) {
      setErrorCheckContactNo(true);
      error = true;
    } else {
      setErrorCheckContactNo(false);
    }
    const value = {
      ...userInfo,
      contactNo,
      address,
      password,
      confirmPassword,
    };

    if (!error) {
      try {
        SellerSignUp(value).then((data) => {
          if (data.ok) {
            updateStep(1);
          } else if (data.status === 500) {
            setSnackBarAlert(true);
            setSnackBarMessage("Email already exists");
          } else if (data.status === 400) {
            setSnackBarAlert(true);
            setSnackBarMessage("Please fill all fields");
          } else {
            setSnackBarMessage("Somthing went wrong please try again");
          }
        });
      } catch (error) {
        setSnackBarAlert(true);
        setSnackBarMessage("Somthing went wrong please try again");
        throw error;
      }
    }
  };



  return (
    <div>
      <br></br>
      <Box sx={boxStyle}>
        <Stack
          direction="row"
          spacing={2}
          sx={{ color: "#093BA7" }}
          alignItems="center"
        >
          <FolderSharedIcon />
          <Typography variant="h5">
            Basic Information for Personal Profile
          </Typography>
        </Stack>

        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                First Name
              </Typography>
              <StyledTextBoxv2
                fullWidth
                error
                placeholder="First Name"
                value={userInfo.firstName}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, firstName: e.target.value })
                }
              />
              <Typography variant="body3" color="red">
                {errorCheckFirstName ? " First name required!" : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Last Name
              </Typography>
              <StyledTextBoxv2
                error={errorCheckLastName ? true : false}
                fullWidth
                placeholder="Last Name"
                value={userInfo.lastName}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, lastName: e.target.value })
                }
              />
              <Typography variant="body3" color="red">
                {errorCheckLastName ? " Last name required!" : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Currency
              </Typography>
              <StyledTextBoxv2 fullWidth value="USD ($)" select>
                <MenuItem value="USD ($)">USD ($)</MenuItem>
              </StyledTextBoxv2>
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Timezone
              </Typography>
              <StyledTextBoxv2
                fullWidth
                value="UTC-4 New York, United States"
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <br></br>
      <Box sx={boxStyle}>
        <Stack
          direction="row"
          spacing={2}
          sx={{ color: "#093BA7" }}
          alignItems="center"
        >
          <LocalPhoneIcon />
          <Typography variant="h5">Personal Contact Information</Typography>
        </Stack>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Contact Number
              </Typography>
              {errorCheckContactNo ? (
                <PhoneInput
                  country={"us"}
                  inputStyle={{
                    borderColor: "#d2302f",
                  }}
                  autoFormat={true}
                  value={contactNo}
                  onChange={setContactNo}
                />
              ) : (
                <PhoneInput
                  country={"us"}
                  autoFormat={true}
                  value={contactNo}
                  onChange={setContactNo}
                />
              )}

              <Typography variant="body3" color="red">
                {errorCheckContactNo ? "Enter a valid phone number" : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Email
              </Typography>
              <StyledTextBoxv2
                error={errorCheckEmail ? true : false}
                fullWidth
                placeholder="joeblake@gmail.com"
                value={userInfo.email}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, email: e.target.value })
                }
              />
              <Typography variant="body3" color="red">
                {emailMessage}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Password
              </Typography>
              <StyledTextBoxv2
                error={errorCheckPassword ? true : false}
                fullWidth
                placeholder="Minimum 8 characters"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
                endadornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Typography variant="body3" color="red">
                {passwordMessage}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Confirm Password
              </Typography>
              <StyledTextBoxv2
                error={errorCheckPasswordConfirm ? true : false}
                fullWidth
                placeholder="Minimum 8 characters"
                value={confirmPassword}
                type={showConfirmPassword ? "text" : "password"}
                endadornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
              <Typography variant="body3" color="red">
                {passwordConfirmMessage}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Address
              </Typography>
              {addressAPIKey ? (
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyD9xcEWBs-_x0-nLofudKB0sT8UeI51Wi0"
                  selectProps={{
                    address,
                    onChange: setAddress,
                    styles: {
                      control: (provided) => ({
                        ...provided,
                        border: "none",
                        borderRadius: "4px",
                        height: "2.5em",
                        background: '#F9F9FB',
                        color: "#28282A",
                      }),
                    },
                  }}
                />
              ) : (
                <StyledTextBoxv2
                  fullWidth
                  value={address}
                  placeholder="Enter Address"
                  onChange={(e) =>
                    setAddress(e.target.value)
                  }
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                State
              </Typography>
              <StyledTextBoxv2
                fullWidth
                placeholder="Arizona"
                value={userInfo.state}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, state: e.target.value })
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Zip code
              </Typography>
              <StyledTextBoxv2
                fullWidth
                placeholder="10008"
                value={userInfo.zipCode}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, zipCode: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <br></br>
        <Grid container justifyContent="flex-start">
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                Country
              </Typography>
              <StyledTextBoxv2 fullWidth placeholder="United State" value={userInfo.country}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, country: e.target.value })
                } />
            </Grid>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} container direction="row" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body2" color="neutral.letter_Black">
                City
              </Typography>
              <StyledTextBoxv2
                fullWidth
                placeholder="Phoenix"
                value={userInfo.city}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, city: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <br></br>
      <Stack justifyContent="flex-end" alignItems="flex-end">
        <GreenContainedButton
          variant="contained"
          onClick={(e) => handleUpdate(e)}
          endIcon={<ArrowForwardIosIcon />}
        >
          Next
        </GreenContainedButton>
      </Stack>
      <br></br>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ p: 6 }}>
          <Typography variant="h1" textAlign="center">
            Email Verification Required
          </Typography>
          <br></br>
          <Typography variant="body1" textAlign="center">
            Thank you for providing your personal information. To proceed with
            onboarding, please verify your email address.
          </Typography>
          <br></br>
          <Typography variant="body1" textAlign="center">
            Please check your inbox for an email from VePay Inc. titled "VePay
            Inc. - Email Verification Link." Click the verification link
            provided to confirm your email address.
          </Typography>
          <br></br>
          <Typography variant="body1" textAlign="center">
            If you don't find the email, kindly check your spam or junk folders.
            For assistance, contact our support team at <b>support@vepay.io.</b>
          </Typography>
          <br></br>
          <Stack alignItems="center">
            <BlackOutlinedButton variant="outlined">
              Resend Email
            </BlackOutlinedButton>
          </Stack>
        </Box>
      </Dialog>

      <Snackbar
        open={snackBarAlert}
        onClose={() => setSnackBarAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{ top: "0 !important", left: "0 !important" }}
        autoHideDuration={6000}
      >
        <Box
          onClose={() => setSnackBarAlert(false)}
          sx={{ width: "100vw", background: "#F33535", p: 0.5 }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: "100%",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              color: "#FFFFFF",
            }}
          >
            <CancelOutlinedIcon fontSize="small" />
            <Typography variant="body2">{snackBarMessage}</Typography>
          </Stack>
        </Box>
      </Snackbar>
    </div>
  );
}

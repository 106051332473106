import React, { useState } from "react";
import {
	Stack,
	Typography,
	Card,
	CardContent,
	Button,
	Dialog,
	List,
	AppBar,
	IconButton,
	Toolbar,
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import SummarizeRoundedIcon from '@mui/icons-material/SummarizeRounded';

import Reports1 from "../../../assets/UserGuide/reports1.png";
import Reports2 from "../../../assets/UserGuide/reports2.png";
import Reports3 from "../../../assets/UserGuide/reports3.png";
import Reports4 from "../../../assets/UserGuide/reports4.png";

export default function ReportsGuide() {
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
	  setOpen(true);
	};
  
	const handleClose = () => {
	  setOpen(false);
	};

	return (
		<div>
			<Card sx={{ minWidth: 275, p:3, textAlign:'center', }} onClick={handleClickOpen}>
				<CardContent>
					<SummarizeRoundedIcon color="primary" sx={{ fontSize: 40 }}>add_circle</SummarizeRoundedIcon>
					<Typography variant="h3">
                        Reports
					</Typography>
				</CardContent>
			</Card>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
			>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
						<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Reports Guide
						</Typography>
						<Button autoFocus color="inherit" onClick={handleClose}>
							Close
						</Button>
					</Toolbar>
				</AppBar>
				<List>
					<Stack direction="row" justifyContent="center" sx={{pt:5}}>
						<Stack spacing={1} sx={{p:3}}>		
							<Typography variant="h3">View and analyze financial and transactional data through interactive reports.</Typography>
                            <img src={Reports1} alt="User Login Page" style={{width: '40%'}}/>
                            <br></br>
                            <Typography variant="h4">1. Transaction Report</Typography>
							<Typography variant="body1" sx={{pl:2}}>- Displays funded amounts, refunds, and marketplace charges.</Typography>
                            <img src={Reports2} alt="User Login Page" style={{width: '40%'}}/>
                            <Typography variant="h4">2. VePay Fee Report</Typography>
							<Typography variant="body1" sx={{pl:2}}>- Shows a detailed breakdown of VePay fees per invoice for complete transparency.</Typography>
                            <img src={Reports3} alt="User Login Page" style={{width: '40%'}}/>
                            <Typography variant="h4">3. Marketplace Payment Report</Typography>
							<Typography variant="body1" sx={{pl:2}}>- Lists all marketplace payments with details like start/end dates, payment status, and received amounts.</Typography>
                            <img src={Reports4} alt="User Login Page" style={{width: '40%'}}/>
                            <Typography variant="h4">2. Generative Report</Typography>
							<Typography variant="body1" sx={{pl:2}}>- An interactive analytics tool providing insights into daily sales, regional performance, top-selling SKUs, return trends, and more.</Typography>
						</Stack>
					</Stack>
				</List>
	  		</Dialog>
			<br></br>
		</div>
	);
}
